<!-- eslint-disable no-unused-vars -->

<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">

        <ul id="masterInfoAssets" class="nav nav-tabs" role="tablist">

          <li class="nav-item" role="presentation">
            <button id="wait-master-info-assets" class="nav-link active" data-bs-toggle="tab"
              data-bs-target="#waitMasterInfoAssets" type="button" role="tab" aria-controls="waitMasterInfoAssets"
              aria-selected="true">{{ $t("content.add1") }}</button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="show-master-info-assets" class="nav-link" data-bs-toggle="tab"
              data-bs-target="#showMasterInfoAssets" type="button" role="tab" aria-controls="showMasterInfoAssets"
              aria-selected="false">{{ $t("content.maddressth") }}</button>
          </li>
        </ul>

        <div id="masterInfoAssetsContent" class="tab-content">

          <div id="waitMasterInfoAssets" class="tab-pane fade show active" role="tabpanel"
            aria-labelledby="wait-master-info-assets">
            <div class="card h-100 mt-2">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5>{{ $t("content.add1") }}</h5>
                    <div>
                      <material-button id="saveTicket" color="primary" class="float-lg-start float-sm-end mb-0"
                        @click="addNewCountryItems">
                        <i class="material-icons material-symbols-outlined">add</i>
                        {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>


              <div class="card-body pt-0">
                <div class="row">
                  <div class="table-responsive">
                    <table data-pagination="true" class="table table-flush">
                      <thead class="thead-light">
                        <tr>

                          <th style="width: 70px;">#</th>
                          <th>
                            {{
                              $t("content.add1") + "(" + $t("content.th") + ")"
                            }}
                          </th>
                          <th>
                            {{
                              $t("content.add1") + "(" + $t("content.en") + ")"
                            }}
                          </th>

                          <th style="width: 100px;">{{ $t('content.act') }}</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr class="group-id-ticket">
                          <td class="text-sm font-weight-normal">
                            <div class="group-tickect">
                              <h6 class="mb-0">
                                <b>{{ btnSearch }}</b>
                              </h6>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input v-model="nameTh" type="text" class="mb-0" :label="lbBankTH" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input v-model="nameEn" type="text" class="mb-0" :label="lbBankEN" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              @click="loadDataCountryApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="addNewc">
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal">
                            <material-input v-model="newnameTh" type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input v-model="newnameEn" type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              :disabled="newnameTh.trim() === '' || newnameEn.trim() === ''"
                              @click="saveNewCountryItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelSaveNewCountryItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-for="(row, index) in listCountry" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString((currentPageC - 1) * perPageC + index + 1) }}
                          </td>
                          <td v-if="row.uid === uidc" class="text-sm font-weight-normal">
                            <material-input v-model="newnameTh" type="text" class="mb-0" />
                          </td>
                          <td v-if="row.uid === uidc" class="text-sm font-weight-normal">
                            <material-input v-model="newnameEn" type="text" class="mb-0" />
                          </td>
                          <td v-if="row.uid === uidc" class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              :disabled="newnameTh.trim() === '' || newnameEn.trim() === ''"
                              @click="saveEditRowCountryItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelEditRowCountryItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                          <td v-if="row.uid !== uidc" class="text-sm font-weight-normal">
                            {{ row.nameTh }}
                          </td>
                          <td v-if="row.uid !== uidc" class="text-sm font-weight-normal">
                            {{ row.nameEn }}
                          </td>
                          <td v-if="row.uid !== uidc" class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              @click="editRowCountryItems(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="deleteRowCountryItems(row)">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="listCountry.length === 0">

                          <td colspan="4">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="4" style="text-align: right">Total Rows : {{ totalRowsC_s }}</td>
                        </tr>
                      </tfoot>
                    </table>

                    <ul v-show="listCountry.length > 0" class="pagination justify-content-end">

                      <div class="project-container">
                        <div class="example-one">
                          <vue-awesome-paginate v-model="currentPageC" :total-items="totalRowsC"
                            :items-per-page="perPageC" @click="onClickHandlerC" />
                        </div>
                      </div>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>


          <div id="showMasterInfoAssets" class="tab-pane fade" role="tabpanel"
            aria-labelledby="show-master-info-assets">
            <div class="card h-100 mt-2">
              <!-- <div class="row h-100"> -->
              <!-- <div class="col-12"> -->
              <div class="card mt-2">
                <div class="card-header">
                  <div class="row no-gutters">
                    <div class="col-12 d-flex justify-content-between align-items-center">
                      <h5>{{ $t("content.maddressth") }}</h5>
                      <div>
                        <material-button id="saveTicket" color="primary" class="float-lg-start float-sm-end mb-0"
                          @click="addNewAddressItems">
                          <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                        </material-button>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="card-body pt-0">
                  <div class="row">
                    <div class="table-responsive" style="min-height:300px">
                      <table class="table table-flush">
                        <thead class="thead-light">
                          <tr>
                            <th style="width: 70px;">#</th>

                            <th>{{ $t('content.add2') }}</th>
                            <th>{{ $t('content.add3') }}</th>
                            <th>{{ $t('content.add4') }}</th>
                            <th>{{ $t('content.add5') }}</th>
                            <th style="width: 100px;">{{ $t('content.act') }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="group-id-ticket">
                            <td class="text-sm font-weight-normal">
                              <div class="group-tickect">
                                <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                              </div>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <div class="input-group">
                                <input v-model="datas.coreBox1" type="text" class="form-control" disabled>
                                <button class="btn btn-outline-primary dropdown-toggle" type="button"
                                  data-bs-toggle="dropdown" aria-expanded="false" :disabled="!listad1_new.length > 0">
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end" style="height: 100%;width: 100% ">
                                  <li>
                                    <div class="input-group">
                                      <input v-model="ad1_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                        autocomplete="off" @:keyup="selectAddressList('1', ad1_inp)" />
                                    </div>
                                  </li>
                                  <li>
                                    <hr class="dropdown-divider">
                                  </li>
                                  <li v-for="(a, corea) in listad1_new" :key="corea" :value="corea"
                                    @click="checkad1(a)">
                                    <a v-if="a.uid !== ad1.uid" class="dropdown-item">
                                      <!-- <material-checkbox v-model="a.usable" :checked="a.usable" class="check-inline"
                                        :disabled="a.uid !== ad1.uid && ad1 !== ''" @change="checkad1(a)" />  -->
                                      {{ a.nameTh }}</a>
                                    <a v-if="a.uid === ad1.uid" class="dropdown-item active bg-primary text-white">
                                      <!-- <material-checkbox v-model="a.usable" :checked="a.usable" class="check-inline"
                                        :disabled="a.uid !== ad1.uid && ad1 !== ''" @change="checkad1(a)" />  -->
                                      {{ a.nameTh }}</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <div class="input-group">
                                <input v-model="datas.coreBox2" type="text" class="form-control" disabled>
                                <button v-show="listad2_new.length > 0" class="btn btn-outline-primary dropdown-toggle"
                                  type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                  :disabled="!listad2_new.length > 0">
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end" style="height: 100%;width: 100% ">
                                  <li>
                                    <div class="input-group">
                                      <input v-model="ad2_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                        autocomplete="off" @:keyup="selectAddressList('2', ad2_inp)" />
                                    </div>
                                  </li>
                                  <li>
                                    <hr class="dropdown-divider">
                                  </li>
                                  <li v-for="(b, coreb) in listad2_new" :key="coreb" @click="checkad2(b)">
                                    <a v-if="b.uid !== ad2.uid" class="dropdown-item">
                                      <!-- <material-checkbox v-model="b.usable" :checked="b.usable" class="check-inline"
                                        :disabled="b.uid !== ad2.uid && ad2 !== ''" @change="checkad2(b)" />  -->
                                      {{ b.nameTh }}</a>
                                    <a v-if="b.uid === ad2.uid" class="dropdown-item active bg-primary text-white">
                                      <!-- <material-checkbox v-model="b.usable" :checked="b.usable" class="check-inline"
                                        :disabled="b.uid !== ad2.uid && ad2 !== ''" @change="checkad2(b)" />  -->
                                      {{ b.nameTh }}</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <div class="input-group">
                                <input v-model="datas.coreBox3" type="text" class="form-control" disabled>
                                <button v-show="listad3_new.length > 0" class="btn btn-outline-primary dropdown-toggle"
                                  type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                  :disabled="!listad3_new.length > 0">
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end" style="height: 100%;width: 100% ">
                                  <li>
                                    <div class="input-group">
                                      <input v-model="ad3_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                        autocomplete="off" @:keyup="selectAddressList('3', ad3_inp)" />
                                    </div>
                                  </li>
                                  <li>
                                    <hr class="dropdown-divider">
                                  </li>
                                  <li v-for="(c, corec) in listad3_new" :key="corec" @click="checkad3(c)">
                                    <a v-if="c.uid !== ad3.uid" class="dropdown-item">
                                      <!-- <material-checkbox v-model="c.usable" :checked="c.usable" class="check-inline"
                                        :disabled="c.uid !== ad3.uid && ad3 !== ''" @change="checkad3(c)" />  -->
                                      {{ c.nameTh }}</a>
                                    <a v-if="c.uid === ad3.uid" class="dropdown-item active bg-primary text-white">
                                      <!-- <material-checkbox v-model="c.usable" :checked="c.usable" class="check-inline"
                                        :disabled="c.uid !== ad3.uid && ad3 !== ''" @change="checkad3(c)" />  -->
                                      {{ c.nameTh }}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <div class="input-group">
                                <input v-model="datas.coreBox4" type="text" class="form-control" disabled>
                                <button v-show="listad4_new.length > 0" class="btn btn-outline-primary dropdown-toggle"
                                  type="button" data-bs-toggle="dropdown" aria-expanded="false"
                                  :disabled="!listad4_new.length > 0">
                                </button>
                                <ul id="target-clicks" class="dropdown-menu dropdown-menu-end"
                                  style="height: 100%;width: 100% ">
                                  <li>
                                    <div class="input-group">
                                      <input v-model="ad4_inp" type="text" class="form-control mb-0" placeholder="ค้นหา"
                                        autocomplete="off" @:keyup="selectAddressList('4', ad4_inp)" />
                                    </div>
                                  </li>
                                  <li>
                                    <hr class="dropdown-divider">
                                  </li>
                                  <li v-for="(d, cored) in listad4_new" id="sel" :key="cored" @click="checkad4(d)">
                                    <a v-if="d.uid !== ad4.uid" class="dropdown-item">
                                      {{ d.postcode }}</a>
                                    <a v-if="d.uid === ad4.uid" class="dropdown-item active bg-primary text-white">
                                      {{ d.postcode }}</a>
                                  </li>
                                </ul>
                              </div>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                                @click="loadDataAddressApi">
                                <i class="material-icons material-symbols-outlined">search</i>
                              </material-button>
                            </td>
                          </tr>

                          <tr v-if="addNewa">
                            <td class="text-sm font-weight-normal">#</td>
                            <td class="text-sm font-weight-normal">
                              <span>
                                <material-input v-model="ad1_inpAth" type="text" class="mb-0"
                                  :disabled="adlv_add !== '1'" :label="lbad1TH" />
                                <br />
                                <material-input v-show="adlv_add === '1'" v-model="ad1_inpAen" type="text" class="mb-0"
                                  :disabled="adlv_add !== '1'" :label="lbad1EN" />
                              </span>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <span>
                                <material-input v-model="ad2_inpAth" type="text" class="mb-0"
                                  :disabled="adlv_add !== '2'" :label="lbad2TH" />
                                <br />
                                <material-input v-show="adlv_add === '2'" v-model="ad2_inpAen" type="text" class="mb-0"
                                  :disabled="adlv_add !== '2'" :label="lbad2EN" />
                              </span>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <span>
                                <material-input v-model="ad3_inpAth" type="text" class="mb-0"
                                  :disabled="adlv_add !== '3'" :label="lbad3TH" />
                                <br />
                                <material-input v-show="adlv_add === '3'" v-model="ad3_inpAen" type="text" class="mb-0"
                                  :disabled="adlv_add !== '3'" :label="lbad3EN" />
                              </span>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <span>
                                <material-input v-model="ad4_inpAth" type="text" class="mb-0" maxlength="5"
                                  :disabled="adlv_add !== '4'" :label="lbad4" @input="onlyDigits_th" />
                                <br />
                              </span>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="primary" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" :disabled="((ad1_inpAth === null || ad1_inpAen === null) && adlv_add === '1')
                                  || ((ad1_inpAth.trim() === '' || ad1_inpAen.trim() === '') && adlv_add === '1')
                                  || ((ad2_inpAth === null || ad2_inpAen === null) && adlv_add === '2')
                                  || ((ad2_inpAth.trim() === '' || ad2_inpAen.trim() === '') && adlv_add.trim() === '2')
                                  || ((ad3_inpAth === null || ad3_inpAen === null) && adlv_add === '3')
                                  || ((ad3_inpAth.trim() === '' || ad3_inpAen.trim() === '') && adlv_add === '3')
                                  || (ad4_inpAth === null && adlv_add === '4')
                                  || (String(ad4_inpAth).length !== 5 && adlv_add === '4')" @click="saveNewItems">
                                <i class="material-icons material-symbols-outlined">save</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                                @click="cancelSaveNewItems">
                                <i class="material-icons material-symbols-outlined">close</i>
                              </material-button>
                            </td>
                          </tr>

                          <tr v-for="(row, index) in listaddressData" :key="index">
                            <td class="text-sm font-weight-normal">
                              {{ formatNumberString((currentPageA - 1) * perPageA + index + 1) }}
                            </td>
                            <td v-if="row.uid === uida && adlv_update === '1'" class="text-sm font-weight-normal">
                              <span>
                                <material-input v-model="ad1_inpAth" type="text" class="mb-0" />
                                <br />
                                <material-input v-model="ad1_inpAen" type="text" class="mb-0" />
                              </span>
                            </td>
                            <td v-if="row.uid === uida && adlv_update === '2'" class="text-sm font-weight-normal">
                              <span>
                                <material-input v-model="ad1_inpAth" type="text" class="mb-0" />
                                <br />
                                <material-input v-model="ad1_inpAen" type="text" class="mb-0" />
                              </span>
                            </td>
                            <td v-if="row.uid === uida && adlv_update === '3'" class="text-sm font-weight-normal">
                              <span>
                                <material-input v-model="ad1_inpAth" type="text" class="mb-0" />
                                <br />
                                <material-input v-model="ad1_inpAen" type="text" class="mb-0" />
                              </span>
                            </td>
                            <td v-if="row.uid === uida && adlv_update === '4'" class="text-sm font-weight-normal">
                              <span>
                                <material-input v-model="ad1_inpAth" type="text" class="mb-0" maxlength="5"
                                  @input="onlyDigits_E" />
                                <br />
                              </span>
                            </td>
                            <td v-if="row.uid !== uida && $i18n.locale === 'th' && adlv_update !== '4'"
                              class="text-sm font-weight-normal">
                              {{ row.nameTh }}
                            </td>
                            <td v-if="row.uid !== uida && $i18n.locale === 'en' && adlv_update !== '4'"
                              class="text-sm font-weight-normal">
                              {{ row.nameEn }}
                            </td>
                            <td v-if="row.uid !== uida && adlv_update === '4'" class="text-sm font-weight-normal">
                              {{ row.postcode }}
                            </td>
                            <td class="text-sm font-weight-normal">
                            </td>
                            <td class="text-sm font-weight-normal">
                            </td>
                            <td class="text-sm font-weight-normal">

                            </td>
                            <td v-if="row.uid === uida" class="text-sm font-weight-normal">
                              <material-button color="primary" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                                :disabled="ad1_inpAth === null || (ad1_inpAen.trim() === '' && adlv_update !== '4') || (adlv_update === '4' && String(ad1_inpAth).length !== 5)"
                                @click="saveEditRowItems">
                                <i class="material-icons material-symbols-outlined">save</i>
                              </material-button>
                              <material-button color="warning" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                                @click="cancelEditRowItems">
                                <i class="material-icons material-symbols-outlined">close</i>
                              </material-button>
                            </td>
                            <td v-if="row.uid !== uida" class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                                @click="editRowItems(row)">
                                <i class=" material-icons material-symbols-outlined">edit</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                                @click="deleteRowItems(row)">
                                <i class="material-icons material-symbols-outlined">delete</i>
                              </material-button>
                            </td>
                          </tr>

                          <tr v-if="listaddressData.length === 0">
                            <td></td>
                            <td></td>
                            <td class="text-sm font-weight-normal">
                              <p style="text-align: center">No Record Found</p>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td colspan="6" style="text-align: right">Total Rows : {{ totalRowsA_s }}</td>
                          </tr>
                        </tfoot>
                      </table>
                      <ul v-show="listaddressData.length > 0" class="pagination justify-content-end">

                        <div class="project-container">
                          <div class="example-one">
                            <vue-awesome-paginate v-model="currentPageA" :total-items="totalRowsA"
                              :items-per-page="perPageA" @click="onClickHandlerA" />
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- </div> -->
                <!-- </div> -->
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, inject, computed, ref } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import { useStore } from "vuex";
// import MaterialCheckbox from "@/components/MaterialCheckbox.vue";
import ExampleFive from "@/examples/ExampleOne";
import { useCountryStore } from '@/stores/country'
import { useDistrictStore } from '@/stores/district'
import { useSubdistrictStore } from '@/stores/subdistrict'
import { usePostCodeStore } from '@/stores/postcode'
import { useProvinceStore } from '@/stores/province'

const storec = useCountryStore()
const stored = useDistrictStore()
const storesd = useSubdistrictStore()
const storepc = usePostCodeStore()
const storep = useProvinceStore()

const { t } = useI18n()
const swal = inject('$swal')
const store = useStore();

const datas = reactive({
  addNew: false,
  rowEdit: false,
  coreCopy: true,
  coreBox1: "",
  coreBox2: "",
  coreBox3: "",
  coreBox4: "",
  demo1: [
    { items: "ประเทศ1" },
    { items: "ประเทศ2" },
    { items: "ประเทศ3" },
    { items: "ประเทศ4" },
    { items: "ประเทศ5" },
    { items: "ประเทศ6" }
  ],
  demo2: [
    { items: "จังหวัด1" },
    { items: "จังหวัด2" },
    { items: "จังหวัด3" },
    { items: "จังหวัด4" },
    { items: "จังหวัด5" },
    { items: "จังหวัด6" }
  ],
  demo3: [
    { items: "อำเภอ1" },
    { items: "อำเภอ2" },
    { items: "อำเภอ3" },
    { items: "อำเภอ4" },
    { items: "อำเภอ5" },
    { items: "อำเภอ6" }
  ],
  demo4: [
    { items: "ตำบล1" },
    { items: "ตำบล2" },
    { items: "ตำบล3" },
    { items: "ตำบล4" },
    { items: "ตำบล5" },
    { items: "ตำบล6" }
  ],
})

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
// const btnCancel = computed(() => t("buttons.canc"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))

const checkBeforeCopy = (bool) => {
  datas.coreCopy = bool
  if (datas.coreBox1 === '') {
    return bool = true
  } else {
    return bool = false
  }
}

const lbBankTH = computed(
  () => t("content.add1") + " (" + t("content.th") + ")"
);
const lbBankEN = computed(
  () => t("content.add1") + " (" + t("content.en") + ")"
);

const lbad1TH = computed(
  () => t("content.add2") + " (" + t("content.th") + ")"
);
const lbad1EN = computed(
  () => t("content.add2") + " (" + t("content.en") + ")"
);

const lbad2TH = computed(
  () => t("content.add3") + " (" + t("content.th") + ")"
);
const lbad2EN = computed(
  () => t("content.add3") + " (" + t("content.en") + ")"
);

const lbad3TH = computed(
  () => t("content.add4") + " (" + t("content.th") + ")"
);
const lbad3EN = computed(
  () => t("content.add4") + " (" + t("content.en") + ")"
);

const lbad4TH = computed(
  () => t("content.add5") + " (" + t("content.th") + ")"
);
const lbad4EN = computed(
  () => t("content.add5") + " (" + t("content.en") + ")"
);

const lbad4 = computed(
  () => t("content.add5")
);

const nameTh = ref("");
const nameEn = ref("");
const listCountry = ref([]);
const newnameTh = ref("");
const newnameEn = ref("");
const addNewc = ref(false);
const rowEditc = ref(false);
const uidc = ref("");
const versionc = ref("");

const listaddressData = ref([]);
const listad1 = ref([]);
const listad2 = ref([]);
const listad3 = ref([]);
const listad4 = ref([]);
const listad1_new = ref([]);
const listad2_new = ref([]);
const listad3_new = ref([]);
const listad4_new = ref([]);
const ad1 = ref("");
const ad2 = ref("");
const ad3 = ref("");
const ad4 = ref("");
const ad1_inp = ref("");
const ad2_inp = ref("");
const ad3_inp = ref("");
const ad4_inp = ref("");
const ad1_inpAth = ref("");
const ad2_inpAth = ref("");
const ad3_inpAth = ref("");
const ad4_inpAth = ref("");
const ad1_inpAen = ref("");
const ad2_inpAen = ref("");
const ad3_inpAen = ref("");
const ad4_inpAen = ref("");
const addNewa = ref(false);
const rowEdita = ref(false);
const uida = ref("");
const versiona = ref("");
const uid_chk = ref("");
const adlv_add = ref("");
const adlv_update = ref("");
const listad_chk = ref([]);

const onClickHandlerC = (page) => {
  // console.log('onClickHandler :' + page);
  updatePaginationCountry()
};
const currentPageC = ref(1);
const totalRowsC = ref(1);
const perPageC = ref(10);
const totalRowsC_s = ref('0');


const onClickHandlerA = (page) => {
  // console.log('onClickHandler :' + page);
  updatePaginationAddress()
};
const currentPageA = ref(1);
const totalRowsA = ref(1);
const perPageA = ref(10);
const totalRowsA_s = ref('0');


const loadDataCountryApi = async (val) => {

  clearItemsCountry();

  if (currentPageC.value !== 1) {
    if (listCountry.value.length === 1 && val === 'del') {
      currentPageC.value = currentPageC.value - 1
    }
  }
  // listCountry.value = []
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameTh: nameTh.value,
    nameEn: nameEn.value
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storec.countryListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listCountry.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRowsC_s.value = formatNumberString(totalRowsC.value)
        // console.log('list : '+list.value.length)
      }
    }
  }

};

loadDataCountryApi();


const loadDataProvinceApi = async () => {

  listad1.value = []
  let list = []
  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: ''
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storep.provinceListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        list.forEach(function (obj) {
          obj.usable = false;
        });
        listad1.value = list
        listad1_new.value = listad1.value
        // console.log('list ad1 : ' + listad1.value.length)
      }
    }
  }
};

loadDataProvinceApi();

async function loadDataAddressApi(val) {

  clearItemsAddressAE()
  findAddressLevel();

  if (currentPageA.value !== 1) {
    if (listaddressData.value.length === 1 && val === 'del') {
      currentPageA.value = currentPageA.value - 1
    }
  }
  await updatePaginationAddress();
  // console.log('adlv_update : ' + adlv_update.value)
  if (adlv_update.value === '1') {
    await loadDataProvince()
    // listaddressData.value = listad_chk.value
    if (ad1_inp.value !== '') {
      listad1_new.value = filterMatchName(ad1_inp, listad1.value)
    } else {
      listad1_new.value = listad1.value
    }
    // console.log('listad1_new : ' + listad1_new.value.length)
  } else if (adlv_update.value === '2') {
    await loadDataDistrictApi(uid_chk.value)
    // listaddressData.value = listad_chk.value
    if (ad2_inp.value !== '') {
      listad2_new.value = filterMatchName(ad2_inp, listad2.value)
    } else {
      listad2_new.value = listad2.value
    }
    // listaddressData.value = await filterDistrictMatch(uid_chk.value, listad2.value)
  } else if (adlv_update.value === '3') {
    await loadDataSubDistrictApi(uid_chk.value)
    // listaddressData.value = listad_chk.value
    if (ad3_inp.value !== '') {
      listad3_new.value = filterMatchName(ad3_inp, listad3.value)
    } else {
      listad3_new.value = listad3.value
    }
    // listaddressData.value = await filterSubDistrictMatch(uid_chk.value, listad3.value)
  } else if (adlv_update.value === '4') {
    await loadDataZipcodeApi(uid_chk.value)
    // listaddressData.value = listad_chk.value
    if (ad4_inp.value !== '') {
      listad4_new.value = filterMatchZip(ad4_inp, listad4.value)
    } else {
      listad4_new.value = listad4.value
    }
    // listaddressData.value = await filterZipcodeMatch(uid_chk.value, listad4.value)
  }

}

async function loadDataProvince(uid) {
  listad_chk.value = []
  listad1.value = []
  let list = []
  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: ''
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storep.provinceListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        list.forEach(function (obj) {
          obj.usable = false;
        });
        listad_chk.value = list
        listad1.value = listad_chk.value
        // console.log('list ad2 : ' + listad_chk.value.length)
      }
    }
  }
}

async function loadDataDistrictApi(uid) {
  listad_chk.value = []
  listad2.value = []
  let list = []
  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: '',
    uid: uid
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await stored.districtListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        list.forEach(function (obj) {
          obj.usable = false;
        });
        listad_chk.value = list
        listad2.value = listad_chk.value
        // console.log('list ad2 : ' + listad_chk.value.length)
      }
    }
  }
}

async function loadDataSubDistrictApi(uid) {
  listad_chk.value = []
  listad3.value = []
  let list = []
  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: '',
    uid: uid
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesd.subdistrictListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        list.forEach(function (obj) {
          obj.usable = false;
        });
        listad_chk.value = list
        listad3.value = listad_chk.value
        // console.log('list ad3 : ' + listad_chk.value.length)
      }
    }
  }
};


async function loadDataZipcodeApi(uid) {
  listad_chk.value = []
  listad4.value = []
  let list = []
  let data = "";
  data = {
    index: 0,
    size: 100,
    nameTh: '',
    uid: uid
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storepc.postcodeListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list = response.data.data.list;
        list.forEach(function (obj) {
          obj.usable = false;
        });
        listad_chk.value = list
        listad4.value = listad_chk.value
        // console.log('list ad4 : ' + listad_chk.value.length)
      }
    }
  }
};

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

async function updatePaginationAddress() {
  loadDataPagination(uid_chk.value, adlv_update)
}

async function loadDataPagination(uid, adlv_update) {
  // console.log("loadDataPagination xxx");
  let data = "";
  let path = "";
  let response = ""
  if (adlv_update.value === '1') {
    path = "provinceListFetch"
    data = {
      index: currentPageA.value - 1,
      size: perPageA.value,
      nameTh: ''
    };
    const payload = data;
    response = await storep.provinceListFetch(payload);
  } else if (adlv_update.value === '2') {
    path = "districtListFetch"
    data = {
      index: currentPageA.value - 1,
      size: perPageA.value,
      nameTh: '',
      uid: uid
    };
    const payload = data;
    response = await stored.districtListFetch(payload);
  } else if (adlv_update.value === '3') {
    path = "subdistrictListFetch"
    data = {
      index: currentPageA.value - 1,
      size: perPageA.value,
      nameTh: '',
      uid: uid
    };
    const payload = data;
    response = await storesd.subdistrictListFetch(payload);
  } else if (adlv_update.value === '4') {
    path = "postcodeListFetch"
    data = {
      index: currentPageA.value - 1,
      size: perPageA.value,
      nameTh: '',
      uid: uid
    };
    const payload = data;
    response = await storepc.postcodeListFetch(payload);
  }


  // console.log("payload : " + JSON.stringify(payload));
  // console.log("path : " + path);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listaddressData.value = response.data.data.list;
        totalRowsA.value = response.data.data.ext.total
        totalRowsA_s.value = formatNumberString(totalRowsA.value)
      }
    }
  }

}

async function updatePaginationCountry() {
  let data = "";
  data = {
    index: currentPageC.value - 1,
    size: perPageC.value,
    nameTh: nameTh.value,
    nameEn: nameEn.value
  };

  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storec.countryListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listCountry.value = response.data.data.list;
        totalRowsC.value = response.data.data.ext.total
        totalRowsC_s.value = formatNumberString(totalRowsC.value)
        // console.log('list : '+list.value.length)
      }
    }
  }
}

async function clearItemsCountry() {
  addNewc.value = false;
  rowEditc.value = false;
  uidc.value = "";
  newnameTh.value = "";
  newnameEn.value = "";
  versionc.value = "";
  // currentPageC.value = 1
}

async function clearItemsAddressAE() {
  addNewa.value = false;
  rowEdita.value = false;
  uida.value = "";
  versiona.value = "";
  ad1_inpAth.value = ""
  ad2_inpAth.value = ""
  ad3_inpAth.value = ""
  ad4_inpAth.value = ""
  ad1_inpAen.value = ""
  ad2_inpAen.value = ""
  ad3_inpAen.value = ""
  ad4_inpAen.value = ""
}

function addAddressLevel() {
  uid_chk.value = "null";
  adlv_add.value = '1'

  if (ad1.value !== '') {
    adlv_add.value = '2'
    uid_chk.value = ad1.value.uid
  }

  if (ad2.value !== '') {
    adlv_add.value = '3'
    uid_chk.value = ad2.value.uid
  }

  if (ad3.value !== '' || ad4.value !== '') {
    adlv_add.value = '4'
    uid_chk.value = ad3.value.uid
  }

}


function findAddressLevel() {

  uid_chk.value = "null";
  adlv_update.value = '1'

  if (ad1.value !== '') {
    uid_chk.value = ad1.value.uid
    adlv_update.value = '2'
  }

  if (ad2.value !== '') {
    uid_chk.value = ad2.value.uid
    adlv_update.value = '3'
  }

  if (ad3.value !== '' || ad4.value !== '') {
    uid_chk.value = ad3.value.uid
    adlv_update.value = '4'
  }
}

async function selectAddressList(num, inp) {
  if (num === '1') {
    listad1_new.value = filterMatchName(inp, listad1.value)
  } else if (num === '2') {
    listad2_new.value = filterMatchName(inp, listad2.value)
  } else if (num === '3') {
    listad3_new.value = filterMatchName(inp, listad3.value)
  } else if (num === '4') {
    listad4_new.value = filterMatchZip(inp, listad4.value)
  }
}

async function checkad1(item) {
  if (item !== ad1.value) {
    // console.log('tc1 true : ' + JSON.stringify(getcatType(item.uid)));
    // } else {
    datas.coreBox1 = ''
    datas.coreBox2 = ''
    datas.coreBox3 = ''
    datas.coreBox4 = ''

    ad1.value = ""
    ad2.value = ""
    ad3.value = ""
    ad4.value = ""

    ad2_inp.value = ""
    ad3_inp.value = ""
    ad4_inp.value = ""

    listad2.value = []
    listad3.value = []
    listad4.value = []

    listad2_new.value = []
    listad3_new.value = []
    listad4_new.value = []

    ad1.value = item
    listad2.value = []
    listad2_new.value = []
    await loadDataDistrictApi(item.uid)
    listad2.value = listad_chk.value
    listad2_new.value = listad_chk.value
    datas.coreBox1 = item.nameTh

  }
}

async function checkad2(item) {
  if (item !== ad2.value) {

    // console.log('tc1 true : ' + JSON.stringify(getcatType(item.uid)));
    // } else {

    datas.coreBox2 = ''
    datas.coreBox3 = ''
    datas.coreBox4 = ''


    ad2.value = ""
    ad3.value = ""
    ad4.value = ""

    ad3_inp.value = ""
    ad4_inp.value = ""

    listad3.value = []
    listad4.value = []

    listad3_new.value = []
    listad4_new.value = []

    ad2.value = item
    listad3.value = []
    listad3_new.value = []
    await loadDataSubDistrictApi(item.uid)
    listad3.value = listad_chk.value
    listad3_new.value = listad_chk.value
    datas.coreBox2 = item.nameTh

  }
}


async function checkad3(item) {
  if (item !== ad3.value) {

    datas.coreBox3 = ''
    datas.coreBox4 = ''

    ad3.value = ""
    ad4.value = ""

    ad4_inp.value = ""

    listad4.value = []

    listad4_new.value = []

    ad3.value = item
    listad4.value = []
    listad4_new.value = []
    await loadDataZipcodeApi(item.uid)
    listad4.value = listad_chk.value
    listad4_new.value = listad_chk.value
    datas.coreBox3 = item.nameTh

  }
}

async function checkad4(item) {
  if (item !== ad4.value) {
    datas.coreBox4 = ''
    ad4.value = ""

    ad4.value = item
    datas.coreBox4 = item.postcode
  }
}



function filterMatchName(str, matchArr) {
  // console.log('str : '+str + ' / matchArr : ' +matchArr)
  return matchArr.filter((c) => c.nameTh.toLowerCase().includes(str.toLowerCase()));
}

function filterMatchZip(str, matchArr) {
  // console.log('str : '+str + ' / matchArr : ' +matchArr)
  return matchArr.filter((c) => c.postcode.includes(String(str)));
}

async function onlyDigits_th() {

  if (ad4_inpAth.value !== "") {
    if (ad4_inpAth.value.match(/^[0-9]+$/)) {
      ad4_inpAth.value = ad4_inpAth.value.match(/^[0-9]+$/);

    } else {
      ad4_inpAth.value = ad4_inpAth.value.slice(0, -1);
    }
  } else {
    ad4_inpAth.value = "";
  }
}

async function onlyDigits_en() {
  if (ad4_inpAen.value !== "") {
    if (ad4_inpAen.value.match(/^[0-9]+$/)) {
      ad4_inpAen.value = ad4_inpAen.value.match(/^[0-9]+$/);
    } else {
      ad4_inpAen.value = ad4_inpAen.value.slice(0, -1);
    }
  } else {
    ad4_inpAen.value = "";
  }
}

async function onlyDigits_E() {
  if (ad1_inpAth.value !== "") {
    if (ad1_inpAth.value.match(/^[0-9]+$/)) {
      ad1_inpAth.value = ad1_inpAth.value.match(/^[0-9]+$/);
    } else {
      ad1_inpAth.value = ad1_inpAth.value.slice(0, -1);
    }
  } else {
    ad1_inpAth.value = "";
  }
}

// async function onlyDigits_inp() {
//   if (ad4_inp.value !== "") {
//     if (ad4_inp.value.match(/^[0-9]+$/)) {
//       ad4_inp.value = ad4_inp.value.match(/^[0-9]+$/);

//     } else {
//       ad4_inp.value = ad4_inp.value.slice(0, -1);
//     }
//     listad4_new.value = filterMatchZip(ad4_inp, listad4.value)
//   } else {
//     ad4_inp.value = "";
//     listad4_new.value = filterMatchZip(ad4_inp, listad4.value)
//   }

// }


async function addNewAddressItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemsAddressAE()
      addAddressLevel();
      addNewa.value = true;
      rowEdita.value = false;
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      addNewa.value = false
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addNewa.value = false
      addaddress()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      addNewa.value = false
      clearItemsAddressAE()
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addNewa.value = false
      clearItemsAddressAE()
    } else if (result.dismiss == 'cancel') {
      addNewa.value = true
    }
  })
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemsAddressAE()
      findAddressLevel()
      addNewa.value = false;
      rowEdita.value = true;
      uida.value = item.uid;
      versiona.value = item.version;
      if (adlv_update.value === '4') {
        ad1_inpAth.value = item.postcode
      } else {
        ad1_inpAth.value = item.nameTh
        ad1_inpAen.value = item.nameEn
      }
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      rowEdita.value = false
      clearItemsAddressAE()
    }
  })
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success")
      rowEdita.value = false
      updateaddress()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      rowEdita.value = false
      clearItemsAddressAE()
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      rowEdita.value = false
      clearItemsAddressAE()
    } else if (result.dismiss == 'cancel') {
      rowEdita.value = true
    }
  })
}

async function deleteRowItems(item) {
  findAddressLevel()
  let name = ''
  if (adlv_update.value === '4') {
    name = item.postcode
  } else {
    name = item.nameTh
  }
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการ " + "' " + name + " ' นี้ใช่ไหม ",
    icon: "warning",
    input: "text",
    inputLabel: "กรุณาพิมพ์ " + "' " + name + " ' เพื่อลบรายการ",
    inputAttributes: {
      "size": "50",
      "autocomplete": "off"
    },
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
    inputValidator: (value) => {
      return new Promise((resolve) => {
        if (value === name) {
          resolve();

          if (item.uid !== "") {
            deleteaddress(item.uid)
          }
        } else {
          resolve("ข้อความที่พิมพ์ไม่ตรงกัน :)");
        }
      });
    }
  });
}


async function addaddress() {

  let data = "";
  let parentUid = uid_chk.value
  let response = ""

  if (adlv_add.value === '1') {
    data = {
      nameTh: ad1_inpAth.value.trim(),
      nameEn: ad1_inpAen.value.trim(),
      path: 'provinceAddFetch'
    };
    const payload = data;
    response = await storep.provinceAddFetch(payload);
    // console.log('payload 1 : ' + JSON.stringify(payload));
  } else if (adlv_add.value === '2') {

    data = {
      nameTh: ad2_inpAth.value.trim(),
      nameEn: ad2_inpAen.value.trim(),
      puid: parentUid,
      path: 'districtAddFetch'
    };
    const payload = data;
    response = await stored.districtAddFetch(payload);
    // console.log('payload 2 : ' + JSON.stringify(payload));
  } else if (adlv_add.value === '3') {

    data = {
      nameTh: ad3_inpAth.value.trim(),
      nameEn: ad3_inpAen.value.trim(),
      duid: parentUid,
      path: 'subdistrictAddFetch'
    };
    const payload = data;
    response = await storesd.subdistrictAddFetch(payload);
    // console.log('payload 3 : ' + JSON.stringify(payload));
  } else if (adlv_add.value === '4') {
    data = {
      postcode: String(ad4_inpAth.value).trim(),
      suid: parentUid,
      path: 'postcodeAddFetch'
    };
    const payload = data;
    response = await storepc.postcodeAddFetch(payload);
    // console.log('payload 4 : ' + JSON.stringify(payload));

  }



  // console.log('addaddress res : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataAddressApi('add');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateaddress() {
  let data = "";
  let parentUid = uid_chk.value
  let response = ""
  if (adlv_update.value === '1') {
    data = {
      uid: uida.value,
      nameTh: ad1_inpAth.value.trim(),
      nameEn: ad1_inpAen.value.trim(),
      version: versiona.value,
      path: 'provinceUpdateFetch'
    };
    const payload = data;
    response = await storep.provinceUpdateFetch(payload);
  } else if (adlv_update.value === '2') {

    data = {
      uid: uida.value,
      nameTh: ad1_inpAth.value.trim(),
      nameEn: ad1_inpAen.value.trim(),
      puid: parentUid,
      version: versiona.value,
      path: 'districtUpdateFetch'
    };
    const payload = data;
    response = await stored.districtUpdateFetch(payload);
  } else if (adlv_update.value === '3') {

    data = {
      uid: uida.value,
      nameTh: ad1_inpAth.value.trim(),
      nameEn: ad1_inpAen.value.trim(),
      duid: parentUid,
      version: versiona.value,
      path: 'subdistrictUpdateFetch'
    };
    const payload = data;
    response = await storesd.subdistrictUpdateFetch(payload);
  } else if (adlv_update.value === '4') {

    data = {
      uid: uida.value,
      postcode: String(ad1_inpAth.value).trim(),
      suid: parentUid,
      version: versiona.value,
      path: 'postcodeUpdateFetch'
    };

    const payload = data;
    response = await storepc.postcodeUpdateFetch(payload);
  }
  // console.log('updateaddress pl : ' + JSON.stringify(payload));
  // console.log('updateaddress res : ' + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataAddressApi('update');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deleteaddress(id) {
  let data = "";
  let response = ""
  if (adlv_update.value === '1') {
    data = {
      uid: id,
      path: 'provinceDeleteFetch'
    };
    const payload = data;
    // console.log('payload 1 : ' + JSON.stringify(payload))
    response = await storep.provinceDeleteFetch(payload);
  } else if (adlv_update.value === '2') {
    data = {
      uid: id,
      path: 'districtDeleteFetch'
    };
    const payload = data;
    // console.log('payload 2 : ' + JSON.stringify(payload))
    response = await stored.districtDeleteFetch(payload);
  } else if (adlv_update.value === '3') {
    data = {
      uid: id,
      path: 'subdistrictDeleteFetch'
    };
    const payload = data;
    // console.log('payload 3 : ' + JSON.stringify(payload))
    response = await storesd.subdistrictDeleteFetch(payload);
  } else if (adlv_update.value === '4') {

    data = {
      uid: id,
      path: 'postcodeDeleteFetch'
    };
    const payload = data;
    // console.log('payload 4 : ' + JSON.stringify(payload))
    response = await storep.postcodeDeleteFetch(payload);
  }
  // console.log('deleteaddress res : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataAddressApi('del');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function addNewCountryItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemsCountry();
      addNewc.value = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      addNewc.value = false
    }
  })
}

async function saveNewCountryItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success")
      addNewc.value = false
      addcountry()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      addNewc.value = false
      clearItemsCountry();
    }
  })
}

async function cancelSaveNewCountryItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addNewc.value = false
      clearItemsCountry();
    } else if (result.dismiss == 'cancel') {
      addNewc.value = true
    }
  })
}

async function editRowCountryItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addNewc.value = false
      rowEditc.value = true
      uidc.value = item.uid;
      versionc.value = item.version;
      newnameTh.value = item.nameTh;
      newnameEn.value = item.nameEn;
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      rowEditc.value = false
      clearItemsCountry();
    }
  })
}

async function saveEditRowCountryItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success")
      rowEditc.value = false
      updatecountry()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      rowEditc.value = false
      clearItemsCountry();
    }
  })
}

async function cancelEditRowCountryItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      rowEditc.value = false
      clearItemsCountry();
    } else if (result.dismiss == 'cancel') {
      rowEditc.value = true
    }
  })
}

async function deleteRowCountryItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deletecountry(item.uid)
      }
    }
  });
}

async function addcountry() {
  let data = "";
  data = {
    nameTh: newnameTh.value,
    nameEn: newnameEn.value,
  };

  const payload = data;
  // console.log('addbank 1 : ' + JSON.stringify(payload));
  const response = await storec.countryAddFetch(payload);
  // console.log('addbank : ' + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataCountryApi('add')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updatecountry() {
  let data = "";
  data = {
    uid: uidc.value,
    nameTh: newnameTh.value,
    nameEn: newnameEn.value,
    version: versionc.value,
  };

  const payload = data;
  // console.log('addbank 1 : ' + JSON.stringify(payload));
  const response = await storec.countryUpdateFetch(payload);
  // console.log('addbank : ' + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataCountryApi('update')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deletecountry(id) {
  let data = "";
  data = {
    uid: id,
  };

  const payload = data;
  // console.log('addbank 1 : ' + JSON.stringify(payload));
  const response = await storec.countryDeleteFetch(payload);
  // console.log('addbank : ' + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataCountryApi('del')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}





</script>

<style lang="scss" scoped></style>