<template>
  <navbar btn-background="bg-gradient-success" />
  <div class="page-header align-items-start min-vh-100 bg-sign-cover">
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container my-auto">
      <div class="row">
        <div class="col-lg-4 col-md-8 col-12 mx-auto">
          <div class="card z-index-0 fadeIn3 fadeInBottom">
            <div class="card-header p-0 position-relative mx-3 z-index-2">
              <div class="d-flex justify-content-center pt-lg-5 pt-3">
                <img :src="logo" class="sign-logo" />
              </div>
            </div>
            <div class="card-body">
              <div class="mb-3">
                <material-input id="username" v-model="user" type="text" :label="t('heads.usr')" name="username" />
              </div>
              <div class="mb-3">
                <material-input id="password" v-model="pwd" type="password" :label="t('heads.pwd')" name="password" />
              </div>
              <material-switch id="rememberMe" v-model="data.remem" name="Remember Me">
                {{ $t("heads.rem") }}
              </material-switch>
              <div class="text-center">
                <material-button class="my-4 mb-2" variant="gradient" color="primary" size="lg" full-width
                  @click="login">
                  {{ $t("heads.log") }}
                </material-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer position-absolute bottom-2 py-2 w-100">
      <div class="container">
        <div class="row align-items-center justify-content-lg-between">
          <div class="col-12 my-auto text-center">
            <div class="copyright text-center text-sm text-white">
              © {{ new Date().getFullYear() }} {{ $t("apps.name") }} {{ $t('apps.version') }} 0.7
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, onBeforeMount, onBeforeUnmount, ref, inject } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min.js'

import Navbar from "@/examples/PageLayout/Navbar.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialSwitch from "@/components/MaterialSwitch.vue";
import MaterialButton from "@/components/MaterialButton.vue";

import logo from "@/assets/img/pawn/nvtx_logo.png";

import { useUserSecurityStore } from "@/stores/usersecurity";



const { t } = useI18n();
const swal = inject("$swal");

const store = useStore();
const userSStore = useUserSecurityStore();


const data = reactive({
  user: "",
  pwds: "",
  remem: false,
});

const public_key = ref('');
const encryptedData = ref('');

const user = ref("");
const pwd = ref("");

// const user = ref("platform01@nvtx.tech");
// const pwd = ref("P@ssw0rd");

// const user = ref("platformadmin@nvtx.tech");
// const pwd = ref("PlatformP@ssw0rd");

const router = useRouter();

function login() {
  if (user.value.trim() === '' || pwd.value.trim() === '') {
    swal("กรุณากรอก Username & Password", "", "error");
  } else {
    getpublicKey()
  }
}

function getpublicKey() {
  userSStore.public().then((resdata) => {
    // console.log("public x : ", JSON.stringify(resdata));
    public_key.value = resdata.key
    getnonce(resdata)
  }).catch((err) => {
    let msg = ''
    if (err.response) {
      if (err.response.status === 408) {
        console.log("Authentication session is timeout. Please try again"); // Please change to alert or notify box.
        console.error("", err);
        msg = 'Authentication session is timeout. Please try again'
      } else if (err.response.status === 401) {
        console.log("Your credential is invalid. Please try again"); // Please change to alert or notify box.
        msg = 'Your credential is invalid. Please try again'
      } else {
        console.error("", err);
        console.log("Authentication Failed. Please try again" + err.response); // Please change to alert or notify box.
        msg = 'Authentication Failed. Please try again' + err.response
      }

    } else {
      msg = 'Unexpected Error. Please reload and try again'
      console.log("Unexpected Error. Please reload and try again"); // Please change to alert or notify box.
    }
    swal("เข้าสู่ระบบไม่สำเร็จ", msg, "error");
  });
}

function getnonce(data) {
  userSStore.nonce(data.key).then((resdata) => {
    // console.log("nonce x : ", JSON.stringify(resdata));
    getlogin_url(resdata)
  }).catch((err) => {
    let msg = ''
    if (err.response) {
      if (err.response.status === 408) {
        console.log("Authentication session is timeout. Please try again"); // Please change to alert or notify box.
        console.error("", err);
        msg = 'Authentication session is timeout. Please try again'
      } else if (err.response.status === 401) {
        console.log("Your credential is invalid. Please try again"); // Please change to alert or notify box.
        msg = 'Your credential is invalid. Please try again'
      } else {
        console.error("", err);
        console.log("Authentication Failed. Please try again" + err.response); // Please change to alert or notify box.
        msg = 'Authentication Failed. Please try again' + err.response
      }

    } else {
      msg = 'Unexpected Error. Please reload and try again'
      console.log("Unexpected Error. Please reload and try again"); // Please change to alert or notify box.
    }
    swal("เข้าสู่ระบบไม่สำเร็จ", msg, "error");
  });
}


async function getlogin_url(data) {
  const hmacDigest = data.nonce + '|:|' + data.aud + '|:|' + pwd.value.trim();
  encryptedData.value = await encrypt(hmacDigest)
  if (encryptedData.value !== '') {
    let data1 = "";
    data1 = {
      encrypttext: encryptedData.value,
      user: user.value.trim(),
    }
    const payload = data1;
    userSStore.login_url(payload).then((logindata) => {
      if (logindata.token) {
        userSStore.authorize(true).then((authData) => {
          if (authData.accessToken) {
            console.log("You are login success"); // Please change to alert or notify box.
            router.push({ name: "Dashboard" });
            // const key2 = JSON.parse(localStorage.getItem("FR-SDK-nvtx-platform"));
            // console.log("exp 1 : ", key2.tokenExpiry);
            // getrefresh()

          } else {
            console.log("You are not allow to access the site. Please contact admin."); // Please change to alert or notify box.
            swal("เข้าสู่ระบบไม่สำเร็จ", "You are not allow to access the site. Please contact admin", "error");
          }
        });
      } else {
        console.log("Authentication Failed. Please try again.");
        swal("เข้าสู่ระบบไม่สำเร็จ", "Authentication Failed. Please try again", "error");
      }
    }).catch((err) => {
      let msg = ''
      if (err.response) {
        if (err.response.status === 408) {
          console.log("Authentication session is timeout. Please try again"); // Please change to alert or notify box.
          console.error("", err);
          msg = 'Authentication session is timeout. Please try again'
        } else if (err.response.status === 401) {
          console.log("Your credential is invalid. Please try again"); // Please change to alert or notify box.
          msg = 'Your credential is invalid. Please try again'
        } else {
          console.error("", err);
          console.log("Authentication Failed. Please try again." + err.response); // Please change to alert or notify box.
          msg = 'Authentication Failed. Please try again' + err.response
        }

      } else {
        msg = 'Unexpected Error. Please reload and try again'
        console.log("Unexpected Error. Please reload and try again."); // Please change to alert or notify box.
      }
      swal("เข้าสู่ระบบไม่สำเร็จ", msg, "error");
    });
  }
}


async function encrypt(text) {
  encryptedData.value = ''
  let encrypt = new JSEncrypt();
  encrypt.setPublicKey(public_key.value);
  let encrypted = encrypt.encrypt(text);
  return encrypted.toString();
}

// function getrefresh() {
//   const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-platform"));
//   // userSStore.refreshData(key.refreshToken)
//   userSStore.refresh(key.refreshToken).then((authData) => {
//     console.log("refresh x : ", JSON.stringify(authData));
//     var d = new Date();
//     d.setSeconds(d.getSeconds() + authData.expires_in);
//     const timestamp = Date.parse(d)
//     // console.log('timestamp : ' + timestamp) // => 1591606075000

//     var obj = {
//       accessToken: authData.access_token,
//       refreshToken: authData.refresh_token,
//       tokenExpiry: timestamp
//     }

//     localStorage.setItem("FR-SDK-nvtx-platform", JSON.stringify(obj));


//     const key2 = JSON.parse(localStorage.getItem("FR-SDK-nvtx-platform"));
//     console.log("exp 2 : ", key2.tokenExpiry);
//   });

// }


function mapMutations() {
  store.commit("toggleEveryDisplay");
  store.commit("toggleHideConfig");
}
mapMutations();

function setDisplayLayouts(bool) {
  store.state.showConfig = bool;
  store.state.showNavbar = bool;
  store.state.showSidenav = bool;
  store.state.showFooter = bool;
  // storei.showConfig = bool;
  // storei.showNavbar = bool;
  // storei.showSidenav = bool;
  // storei.showFooter = bool;
}

onBeforeMount(() => {
  // mapMutations()
  setDisplayLayouts(false);
  userSStore.clearSession();
});

onBeforeUnmount(() => {
  // mapMutations()
  setDisplayLayouts(true);
});
</script>

<style lang="scss" scoped>
.bg-sign-cover {
  background-image: url("~@/assets/img/bg-pricing.jpg");
}

.sign-logo {
  max-width: 200px;
  margin: 0 auto;
}
</style>
