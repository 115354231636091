<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5>{{ $t("content.mbank") }}</h5>
                <div>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                    <i class="material-icons material-symbols-outlined">add</i>
                    {{ btnAddNew }}
                  </material-button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>
                        {{
                          $t("content.mbankname") + "(" + $t("content.th") + ")"
                        }}
                      </th>
                      <th>
                        {{
                          $t("content.mbankname") + "(" + $t("content.en") + ")"
                        }}
                      </th>
                      <th>
                        {{
                          $t("content.bankcode")
                        }}
                      </th>
                      <th width="250px">{{ $t("content.mlogo") }}</th>
                      <th v-if="addNew || rowEdit"></th>

                      <th width="100px">{{ $t("content.mcolor") }}</th>
                      <th>{{ $t("content.act") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="group-id-ticket">
                      <td class="text-sm font-weight-normal">
                        <div class="group-tickect">
                          <h6 class="mb-0">
                            <b>{{ btnSearch }}</b>
                          </h6>
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="nameTh" type="text" class="mb-0" :label="lbBankTH" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="nameEn" type="text" class="mb-0" :label="lbBankEN" />
                      </td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal"></td>
                      <td v-if="addNew || rowEdit" class="text-sm font-weight-normal"> </td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch" @click="loadDataApi">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-if="addNew">
                      <td class="text-sm font-weight-normal">#</td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="newnameTh" type="text" class="mb-0" value="" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="newnameEn" type="text" class="mb-0" value="" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="newCode" type="text" class="mb-0" value="" maxlength="3"
                          @input="onlyDigits" />
                        <!-- <InputNumber v-model="newCode" autocomplete="off" @focus="$event.target.select()"
                          maxlength="3" /> -->
                      </td>
                      <td class="text-sm font-weight-normal">
                        <div class="input-group custom-file-button">
                          <label for="upload" class="input-group-text mb-0">{{
                            $t("buttons.file")
                          }}</label>
                          <input id="upload" type="file" accept="image/*" class="form-control mb-0" @change="addFile" />
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <div class="bank-logo">
                          <!-- <img :src="logo" alt="" /> -->
                          <img :src="preview_a" alt="" max-width="200" max-height="200" aspect-ratio="1" />
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="newcolor" type="color" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" :disabled="newnameEn.trim() === '' ||
                            newnameTh.trim() === '' ||
                            newcolor === '' || thumb === null || newCode === ''
                            " @click="saveNewItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelSaveNewItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-for="(row, index) in list" :key="index">
                      <td class="text-sm font-weight-normal">
                        {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-input v-model="newnameTh" type="text" class="mb-0" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-input v-model="newnameEn" type="text" class="mb-0" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-input v-model="newCode" type="text" class="mb-0" value="" maxlength="3"
                          @input="onlyDigits" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <div class="input-group custom-file-button">
                          <label for="uploadEdit" class="input-group-text mb-0">{{ $t("buttons.file")
                          }}</label>
                          <input id="uploadEdit" type="file" class="form-control mb-0" accept="image/*" title="abc"
                            @change="addFile" />
                        </div>
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <div class="bank-logo">
                          <!-- <img :src="logo" alt="" /> -->
                          <img :src="preview_e" alt="" max-width="200" max-height="200" aspect-ratio="1" />
                        </div>
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-input v-model="newcolor" type="color" class="mb-0" />
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" :disabled="newnameEn.trim() === '' ||
                            newnameTh.trim() === '' ||
                            newcolor === '' || thumb === null || newCode === ''" @click="saveEditRowItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelEditRowItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        {{ row.nameTh }}
                      </td>

                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        {{ row.nameEn }}
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        {{ row.code }}
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        <div class="bank-logo">
                          <!-- <img :src="logo" alt="" /> -->
                          <img :src="row.thumb" alt="" max-width="200" max-height="200" aspect-ratio="1" />
                        </div>
                      </td>
                      <td v-if="row.uid !== uid && (addNew || rowEdit)" class="text-sm font-weight-normal">
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        <material-input v-model="row.color" type="color" disabled />
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        <material-button color="info" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="btnEdit" @click="editRowItems(row)">
                          <i class="material-icons material-symbols-outlined">edit</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel" @click="deleteRowItems(row)">
                          <i class="material-icons material-symbols-outlined">delete</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-if="list.length === 0">
                      <td></td>
                      <td></td>
                      <td class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr v-if="!rowEdit && !addNew">
                      <td colspan="7" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                    <tr v-if="rowEdit || addNew">
                      <td colspan="8" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                  </tfoot>
                </table>
                <ul v-show="list.length > 0" class="pagination justify-content-end">
                  <div class="project-container">
                    <div class="example-one">
                      <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                        @click="onClickHandler" />
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, inject, computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import InputNumber from 'primevue/inputnumber';
import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";

import logo from "@/assets/img/pawn/nvtx_logo.png";
// import { useStore } from "vuex";
import * as st from "@/config/setting.js";
import { useBankStore } from '@/stores/bank'
import { useDocumentStore } from '@/stores/document'
import axios from "axios";
import noimg from "@/assets/img/noimg.png"

const storeb = useBankStore()
const { t } = useI18n();
const swal = inject("$swal");
const stored = useDocumentStore()


// const datas = reactive({
//   addNew: false,
//   rowEdit: false,
//   demo1: [
//     { items: "ประเภท1.1" },
//     { items: "ประเภท1.2" },
//     { items: "ประเภท1.3" },
//     { items: "ประเภท1.4" },
//     { items: "ประเภท1.5" },
//     { items: "ประเภท1.6" },
//   ],
// });

const btnAddNew = computed(() => t("buttons.impo"));
const btnEdit = computed(() => t("buttons.edit"));
const btnSearch = computed(() => t("buttons.sear"));
const btnSave = computed(() => t("buttons.save"));
// const btnCancel = computed(() => t("buttons.canc"))
const btnDel = computed(() => t("buttons.remo"));
const btnClose = computed(() => t("buttons.clos"));

const lbBankTH = computed(
  () => t("content.mbankname") + " (" + t("content.th") + ")"
);
const lbBankEN = computed(
  () => t("content.mbankname") + "(" + t("content.en") + ")"
);
// const lbBankLogo = computed(() => t("content.mlogo"))

// const store = useStore();
const nameTh = ref("");
const nameEn = ref("");
const list = ref([]);
const newlogo = ref("");
const newnameTh = ref("");
const newnameEn = ref("");
const newCode = ref("");
const newcolor = ref("#000000");
const addNew = ref(false);
const rowEdit = ref(false);
const uid = ref("");
const version = ref("");

const thumb_name = ref("");
const thumb_id = ref("");
const thumb = ref(null);
const file_d = ref([]);
const urls = ref([]);
const file_L = ref([]);
const thumb_old = ref(null);
const file_old = ref(null);
const data_old = ref(null);
const preview_a = ref("");
const preview_e = ref("");
const oldlogo = ref("");
const lb = ref([]);
const regex = /data:.*base64,/
const mimeType = ref("");

const onClickHandler = async (page) => {
  // console.log('onClickHandler :' + page);
  await updatePagination()
};
const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();


const loadDataApi = async (val) => {
  clearItems();
  let data = "";
  lb.value = [];
  urls.value = []
  if (currentPage.value !== 1) {
    if (list.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    nameTh: nameTh.value,
    nameEn: nameEn.value,
  };
  const payload = data;
  // const response = await store.dispatch("bankListFetch", payload);
  const response = await storeb.bankListFetch(payload);

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        // console.log("loadDataApi : " + JSON.stringify(response.data.data.list));
        lb.value = response.data.data.list;
        lb.value.forEach(function (obj) {
          obj.thumb = noimg;
          obj.file = null;
          obj.data = null;
        });
        // console.log("lb : " + JSON.stringify(lb));
        for (let i = 0; i < lb.value.length; i++) {
          // console.log("logoUid : " + lb[i].logoUid);
          if (lb.value[i].logoUid !== "0") {
            urls.value.push(String(st.url_api + "/doc-svc/document/info/" + lb.value[i].logoUid));
          }
        }
        // console.log("urls : " + JSON.stringify(urls.value));
        if (urls.value.length > 0) {
          // await getFile2()
          await getFileLoco()
        }
        list.value = lb.value
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
};

loadDataApi();

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

async function onlyDigits() {

  if (newCode.value !== "") {
    if (newCode.value.match(/^[0-9]+$/)) {
      newCode.value = newCode.value.match(/^[0-9]+$/);

    } else {
      newCode.value = newCode.value.slice(0, -1);
    }
  } else {
    newCode.value = "";
  }
}

async function updatePagination() {
  urls.value = []
  let data = "";
  lb.value = [];
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    nameTh: nameTh.value,
    nameEn: nameEn.value,
  };
  const payload = data;
  // const response = await store.dispatch("bankListFetch", payload);
  const response = await storeb.bankListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        lb.value = response.data.data.list;
        lb.value.forEach(function (obj) {
          obj.thumb = noimg;
          obj.file = null;
          obj.data = null;
        });
        // console.log("lb : " + JSON.stringify(lb));
        for (let i = 0; i < lb.value.length; i++) {
          // console.log("logoUid : " + lb[i].logoUid);
          if (lb.value[i].logoUid !== "0") {
            urls.value.push(String(st.url_api + "/doc-svc/document/info/" + lb.value[i].logoUid));
          }
        }
        // console.log("urls : " + JSON.stringify(urls.value));
        if (urls.value.length > 0) {
          // await getFile2()
          await getFileLoco()
        }
        list.value = lb.value
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)

      }
    }
  }
}


function filterFile(str, matchArr) {
  //  return matchArr.filter((c) => c.data.data.uid === str);
  return matchArr.filter((c) => c.logoUid === str);
}

async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
      addNew.value = true;
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error");
      addNew.value = false;
      clearItems();
    }
  });
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success");
      addNew.value = false;
      addbank();
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการบันทึก", "", "error");
      addNew.value = false;
      clearItems();
    }
  });
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      addNew.value = false;
      clearItems();
    } else if (result.dismiss == "cancel") {
      addNew.value = true;
    }
  });
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
      addNew.value = false;
      rowEdit.value = true;
      uid.value = item.uid;
      newnameTh.value = item.nameTh;
      newnameEn.value = item.nameEn;
      newlogo.value = item.logoUid;
      newcolor.value = item.color;
      version.value = item.version;
      thumb.value = item.thumb
      thumb_old.value = item.thumb
      file_old.value = item.file
      data_old.value = item.data
      preview_e.value = item.thumb
      oldlogo.value = item.logoUid
      newCode.value = item.code
      // console.log('item1 : ' + JSON.stringify(item));
      // console.log('version : ' + version.value)
      // console.log('thum e1 : ' + item.file)
      // console.log('thum e1 : ' + item.logoUid)

      // document.querySelector('#uploadEdit').files[0] = item.file;
      // const selectedFile = document.querySelector('#uploadEdit').files = item.file


    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error");
      rowEdit.value = false;
      clearItems();
    }
  });
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success");
      rowEdit.value = false;
      if (uid.value !== "" && version.value !== "") {
        updatebank();
      }
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกแก้ไข", "", "error");
      rowEdit.value = false;
      clearItems();
    }
  });
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      rowEdit.value = false;
      clearItems();
    } else if (result.dismiss == "cancel") {
      rowEdit.value = true;
    }
  });
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deletebank(item.uid);
      }
    }
  });
}

async function addbank() {
  if (thumb.value !== null && thumb_name.value !== '') {
    await addfileApi()
  }
  let data = "";
  data = {
    nameTh: newnameTh.value,
    nameEn: newnameEn.value,
    logoUid: thumb_id.value,
    color: newcolor.value,
    code: String(newCode.value)
  };

  const payload = data;
  // console.log('addbank 1 : ' + JSON.stringify(payload));
  const response = await storeb.bankAddFetch(payload);
  // console.log('addbank : ' + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('add');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updatebank() {

  if (newlogo.value === "" && thumb.value !== null && thumb_name.value !== '') {
    await updatefileApi()
    newlogo.value = thumb_id.value
  }

  let data = "";
  data = {
    uid: uid.value,
    nameTh: newnameTh.value,
    nameEn: newnameEn.value,
    logoUid: newlogo.value,
    color: newcolor.value,
    version: version.value,
    code: String(newCode.value)
  };
  const payload = data;
  // console.log('updatebank : ' + JSON.stringify(payload));
  const response = await storeb.bankUpdateFetch(payload);
  // console.log('loadDataApi : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('update');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deletebank(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;
  // const response = await store.dispatch("bankDeleteFetch", payload);
  const response = await storeb.bankDeleteFetch(payload);
  // console.log('loadDataApi : '+JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}
async function clearItems() {
  addNew.value = false;
  rowEdit.value = false;
  uid.value = "";
  version.value = "";
  newnameTh.value = "";
  newnameEn.value = "";
  newlogo.value = "";
  newcolor.value = "#000000";
  thumb.value = null
  thumb_old.value = null
  file_old.value = null
  data_old.value = null
  preview_e.value = ""
  preview_a.value = ""
  oldlogo.value = "";
  newCode.value = ""
}

async function addFile(e) {
  file_d.value = []
  thumb.value = null
  thumb_name.value = ""
  newlogo.value = ""
  preview_a.value = ""
  preview_e.value = ""
  mimeType.value = ""
  let date = new Date()
  if (e.target.files[0] !== undefined) {
    file_d.value = e.target.files[0];
    thumb.value = await toBase64(e.target.files[0])
    thumb_name.value = 'banklogo-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
    if (e.target.files[0].type === "image/jpeg") {
      thumb_name.value = thumb_name.value + ".jpg"
      mimeType.value = "image/jpeg"
    } else if (e.target.files[0].type === "image/png") {
      thumb_name.value = thumb_name.value + ".png"
      mimeType.value = "image/png"
    }
    if (uid.value !== '') {
      preview_e.value = URL.createObjectURL(e.target.files[0]);
    } else {
      preview_a.value = URL.createObjectURL(e.target.files[0]);
    }
  }

}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace('data:', '')
    .replace(/^.+,/, ''));
  reader.onerror = reject;
});


async function addfileApi() {
  let data = "";
  let trimmedString = String(thumb.value).replace(regex, "")
  data = {
    name: thumb_name.value,
    data: trimmedString,
    mimeType: mimeType.value,
  };

  const payload = data;
  // console.log('addfileApi : ' + JSON.stringify(payload));
  const response = await stored.addFetch(payload);
  // console.log('addfileApi : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = response.data.data.uid
        // console.log('uid : ' + thumb_id.value);
      } else {
        swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}
async function updatefileApi() {
  let data = "";
  let trimmedString = String(thumb.value).replace(regex, "")
  data = {
    uid: oldlogo.value,
    name: thumb_name.value,
    data: trimmedString,
    version: version.value,
    mimeType: mimeType.value,
  };

  const payload = data;
  // console.log('updatefileApi : ' + JSON.stringify(payload.data));
  const response = await stored.updateFetch(payload);
  // console.log('updatefileApi : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = oldlogo.value
      } else {
        swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function getFileLoco() {
  file_L.value = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-platform"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.value.map((url) => axios.get(url));
  await axios.all(requests).then(allResults => {
    if (allResults) {
      file_L.value = allResults
      if (file_L.value !== null) {

        for (let i = 0; i < file_L.value.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.data.name));
          if (file_L.value[i].data.data !== null) {
            const file = getFileFromBase64(file_L.value[i].data.data.data, file_L.value[i].data.data.name)
            const data = URL.createObjectURL(file);
            const lf = filterFile(file_L.value[i].data.data.uid, lb.value)
            lf[0].thumb = data
            lf[0].file = file
            lf[0].data = data
          }
        }
      }
    }
  })

}




function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  const imageContent = atob(trimmedString);
  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }
  var ext = fileName.split('.').pop();
  let type = '';
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  }
  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}


</script>

<style lang="scss" scoped></style>
